<template>
    <div>
        <div
            class="choiceCar-tit"
            @click="goBack"
        >
            <font color="#ff470f">返回上一页</font>
        </div>
        <div class="exercises widthCen content">
            <div class="exercises_padding">
                <!-- 01 -->
                <div
                    class="head_div float"
                    v-if="question"
                >
                    <div class="head_left left-width">
                        <div class="left_title zhong">

                            <p> <span>{{question.QuestionTypeStr}}</span>{{questionTitle}}</p>
                        </div>
                        <!-- 选择 -->
                        <div class="left_content">
                            <template v-if="question.QuestionType === 3">
                                <ul>
                                    <li
                                        v-for="(item, index) in question.AnswerList"
                                        :key="index"
                                        @click="toggleClick(item.Option)"
                                    >
                                        <img :src="answerState(item.Option)" />
                                        <p>{{ item.Option }}</p>
                                    </li>
                                </ul>
                            </template>
                            <template v-else>
                                <ul>
                                    <li
                                        v-for="(item, index) in question.AnswerList"
                                        :key="index"
                                        @click="toggleClick(item.Option)"
                                    >
                                        <img :src="answerState(item.Option)" />
                                        <p>{{ `${item.Option}. ${item.Content}` }}</p>
                                    </li>
                                </ul>
                            </template>
                        </div>
                        <!-- 按钮 -->
                        <div class="left_down">
                            <div
                                class="left_fgruop zhong center"
                                @click="prev"
                            >
                                <i class="el-icon-arrow-left"></i>
                                <p>上一题</p>
                            </div>
                            <div
                                class="left_fgruop zhong center"
                                @click="next"
                            >
                                <p>{{currentIndex >= idList.length - 1 ? '完成' : '下一题'}}</p>
                                <i
                                    class="el-icon-arrow-right"
                                    v-if="currentIndex < idList.length - 1"
                                ></i>
                            </div>
                            <button
                                class="left_fgruop left_btn"
                                v-if="question.QuestionType === 2"
                                @click="confirmSelect"
                            >
                                确认选择
                            </button>
                        </div>
                    </div>

                    <!-- 图 -->
                    <div
                        class="right"
                        v-if="question.ImgId > 0"
                        style="width: 650px;"
                    >
                        <div class="right_img"><img
                                :src="question.SmallImgUrl || '../../images/seat.png'"
                                alt=""
                                style="object-fit: contain;"
                            /></div>
                        <p @click="showPopup()">
                            查看大图
                            <i class="el-icon-arrow-right"></i>
                        </p>
                    </div>
                </div>
                <template v-else>
                    <no-content></no-content>
                </template>
                <!-- 02 -->
                <div
                    class="exercises_label"
                    v-if="question"
                >
                    <div class="exercises_fg zhong">
                        <div
                            class="label_fg zhong center"
                            @click="autoPlay"
                        >
                            <p> {{ isAutoPlay ? '停止播放' : '自动播放'}}</p>
                        </div>
                        <div
                            class="label_fg zhong center"
                            @click="playAudio"
                        >
                            <p> {{question.VoiceUrlAnaly ? '语音讲解' : '暂无语音'}}</p>
                        </div>
                        <div
                            class="label_fg zhong center"
                            @click="board()"
                        >
                            <p>{{ isFlag ? '收起题板' : '展开题板' }}</p>
                            <i :class="isFlag ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
                        </div>
                        <div
                            class="label_fg zhong center"
                            @click="explain()"
                        >
                            <p>{{ isExplain ? '收起解释' :'展开解释' }}</p>
                            <i :class="isExplain ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
                        </div>
                    </div>
                </div>

                <!-- 题板 -->
                <exercises-board
                    :isShow="isFlag"
                    :list="idList"
                    :currentIndex.sync="currentIndex"
                    @indexClick="handleIndexClick"
                ></exercises-board>

                <!-- 技巧讲解 -->
                <template v-if="question">
                    <div
                        class="skill animated fadeIn"
                        v-show="isExplain"
                    >
                        <p>解析</p>
                        <div class="skill_top">
                            <div
                                class="skill_img"
                                v-if="question.AnalysisImg > 0"
                            ><img :src="question.AnalysisImgUrl || require('../../images/seat.png')" /></div>
                            <div class="skill_right">{{question.AnalysisContent}}</div>
                        </div>
                    </div>
                </template>

                <!-- 图片放大 -->
                <template v-if="question && question.ImgId > 0">
                    <div
                        class="popup"
                        v-show="isPopup"
                    >
                        <div
                            class="popup_back"
                            @click="hidePopup()"
                        ></div>
                        <div class="exercises_popup animated fadeIn"><img style="width: 600px;" :src="question.ImgUrl || '../../images/wide_seat.png'" /></div>
                    </div>
                </template>
            </div>
            <!-- 离开 -->
            <div
                class="popup"
                v-show="noticeVisible"
            >
                <div class="active-popup animated fadeIn leave-height">
                    <div class="popup-modelTest-tit">操作提示</div>
                    <div class="popup-modelTest-con">
                        <p>是否重新答题？</p>
                    </div>
                    <div class="vip-popup-btn flex flex-jc">
                        <div
                            class="popup-btn01"
                            @click="notRestart"
                        >取消</div>
                        <div
                            class="popup-btn02"
                            @click="restart"
                        >确定</div>
                    </div>
                </div>
            </div>
            <template v-if="question">
                <audio
                    :src="question.VoiceUrlAnaly"
                    preload="auto"
                    controls="controls"
                    ref="audioPlayer"
                    style="display:none;"
                ></audio>
                <audio
                    :src="question.VoiceUrl"
                    preload="auto"
                    controls="controls"
                    ref="titleAudioPlayer"
                    style="display:none;"
                ></audio>
            </template>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/styles/public.scss';
@import '@/styles/exercises.scss';
</style>
<script>
import exercisesBoard from '../../components/exercisesBoard.vue';
export default {
    name: '',
    components: { exercisesBoard },
    data() {
        return {
            intervalArr: [],
            timeOutArr: [],
            isAutoPlay: false,
            noticeVisible: false,
            name: false,
            isFlag: false,
            isExplain: false,
            // 弹窗
            judge: ['对', '错'],
            isPopup: false,
            idList: [],
            currentIndex: 0,
            currentId: 0,
            question: null,
            questionList: [],
            answerList: [],
            currentAnswer: []
        };
    },
    created() {
        this.getQuestionIdList();
    },
    computed: {
        questionTitle() {
            return `${this.currentIndex + 1}/${this.idList.length}    ${this.question.Title}`;
        }
    },
    watch: {
        currentIndex() {
            this.currentAnswer = [];
            this.indexChange();
        }
    },
    beforeDestroy() {
        this.clearAllAsyncFn();
    },
    methods: {
        // 清除所有异步延迟方法（macrotask）
        clearAllAsyncFn() {
            if (this.timeOutArr.length > 0) {
                this.timeOutArr.forEach(item => {
                    clearTimeout(item);
                });
            }
            if (this.intervalArr.length > 0) {
                this.intervalArr.forEach(item => {
                    clearInterval(item);
                });
            }
        },
        // （停止）自动播放
        autoPlay() {
            const player = this.$refs.audioPlayer;
            const titleplayer = this.$refs.titleAudioPlayer;
            if (this.isAutoPlay) {
                player.pause();
                titleplayer.pause();
                player.currentTime = 0;
                titleplayer.currentTime = 0;
                this.clearAllAsyncFn();
                this.isAutoPlay = !this.isAutoPlay;
                return;
            }
            if (!this.question.VoiceUrl) {
                this.$message({
                    type: 'error',
                    message: '本题题干暂无语音，自动播放已停止',
                    showClose: true
                });
                this.clearAllAsyncFn();
                this.isAutoPlay = false;
                return;
            }
            if (!this.question.VoiceUrlAnaly) {
                this.$message({
                    type: 'error',
                    message: '本题暂无语音讲解，自动播放已停止',
                    showClose: true
                });
                this.clearAllAsyncFn();
                this.isAutoPlay = false;
                return;
            }
            this.isAutoPlay = !this.isAutoPlay;
            if (!player.paused) {
                player.pause();
            }
            if (!titleplayer.paused) {
                titleplayer.pause();
            }
            if (titleplayer.readyState > 1 && titleplayer.duration > 0) {
                titleplayer.currentTime = 0;
                titleplayer.play();
                const timeOut = setTimeout(() => {
                    const idx = this.timeOutArr.findIndex(a => a === timeOut);
                    this.timeOutArr.splice(idx, 1);
                    if (this.question.QuestionType === 2) {
                        this.$set(this.answerList, this.currentIndex, this.question.AnswerIds.split(','));
                    } else {
                        this.$set(this.answerList, this.currentIndex, this.question.AnswerIds);
                    }
                    this.playAnalysis(player);
                }, titleplayer.duration * 1000);
                this.timeOutArr.push(timeOut);
            } else {
                titleplayer.load();
                // this.$message({
                //     type: 'warning',
                //     message: '语音加载中...',
                //     showClose: true
                // });
                const interval = setInterval(() => {
                    if (!titleplayer) {
                        const idx = this.intervalArr.findIndex(a => a === interval);
                        this.intervalArr.splice(idx, 1);
                        clearInterval(interval);
                    }
                    if (titleplayer.readyState > 1) {
                        const idx = this.intervalArr.findIndex(a => a === interval);
                        this.intervalArr.splice(idx, 1);
                        // this.$message({
                        //     type: 'success',
                        //     message: '语音加载成功',
                        //     showClose: true
                        // });
                        titleplayer.currentTime = 0;
                        titleplayer.play();
                        const timeOut = setTimeout(() => {
                            const idx = this.timeOutArr.findIndex(a => a === timeOut);
                            this.timeOutArr.splice(idx, 1);
                            if (this.question.QuestionType === 2) {
                                this.$set(this.answerList, this.currentIndex, this.question.AnswerIds.split(','));
                            } else {
                                this.$set(this.answerList, this.currentIndex, this.question.AnswerIds);
                            }
                            this.playAnalysis(player);
                        }, titleplayer.duration * 1000);
                        this.timeOutArr.push(timeOut);
                        clearInterval(interval);
                    }
                }, 200);
                this.intervalArr.push(interval);
            }
        },
        // 播放语音讲解
        playAnalysis(player) {
            if (player.readyState > 1) {
                player.currentTime = 0;
                player.play();
                if (this.isAutoPlay) {
                    const timeOut = setTimeout(() => {
                        const idx = this.timeOutArr.findIndex(a => a === timeOut);
                        this.timeOutArr.splice(idx, 1);
                        this.next();
                    }, player.duration * 1000);
                    this.timeOutArr.push(timeOut);
                }
            } else {
                player.load();
                // this.$message({
                //     type: 'warning',
                //     message: '语音加载中...',
                //     showClose: true
                // });
                const interval = setInterval(() => {
                    if (!player) {
                        const idx = this.intervalArr.findIndex(a => a === interval);
                        this.intervalArr.splice(idx, 1);
                        clearInterval(interval);
                    }
                    if (player.readyState > 1) {
                        const idx = this.intervalArr.findIndex(a => a === interval);
                        this.intervalArr.splice(idx, 1);
                        // this.$message({
                        //     type: 'success',
                        //     message: '语音加载成功',
                        //     showClose: true
                        // });
                        player.currentTime = 0;
                        player.play();
                        if (this.isAutoPlay) {
                            const timeOut = setTimeout(() => {
                                const idx = this.timeOutArr.findIndex(a => a === timeOut);
                                this.timeOutArr.splice(idx, 1);
                                this.next();
                            }, player.duration * 1000);
                            this.timeOutArr.push(timeOut);
                        }
                        clearInterval(interval);
                    }
                }, 200);
                this.intervalArr.push(interval);
            }
        },
        // 播放语音讲解点击事件
        playAudio() {
            if (this.isAutoPlay) {
                const player = this.$refs.audioPlayer;
                const titleplayer = this.$refs.titleAudioPlayer;
                player.pause();
                titleplayer.pause();
                player.currentTime = 0;
                titleplayer.currentTime = 0;
                this.clearAllAsyncFn();
                this.isAutoPlay = !this.isAutoPlay;
            }
            if (!this.question.VoiceUrlAnaly) {
                this.$message({
                    type: 'error',
                    message: '本题暂无语音讲解',
                    showClose: true
                });
                return;
            }
            const player = this.$refs.audioPlayer;
            if (!player.paused && player.currentTime < player.duration) {
                return;
            }
            this.playAnalysis(player);
        },
        // 不重新答题
        notRestart() {
            this.currentIndex = this.idList.findIndex(a => a.ProblemId === this.lastProblemId);
            this.noticeVisible = false;
        },
        // 重新答题
        restart() {
            // this.idList.filter(a => a.IsTest).forEach(a => {
            //     a.AnswerIds = null;
            //     a.IsError = a.IsTest = false;
            // });
            // this.answerList = [];
            this.removeRecord();
            this.noticeVisible = false;
        },
        /**
         * 当前题目改变
         */
        indexChange() {
            const item = this.idList[this.currentIndex];
            if (item) {
                this.currentId = item.ProblemId;
                const question = this.questionList[this.currentIndex];
                if (question) {
                    this.question = question;
                    if (this.isAutoPlay) {
                        this.isAutoPlay = false;
                        this.$nextTick(() => {
                            this.autoPlay();
                        });
                    }
                } else {
                    this.getQuestion();
                }
            }
        },
        /**
         * 获取具体题目
         */
        getQuestion() {
            this.$api.getSequenceQuestion({ problemId: this.currentId }).then(res => {
                if (res.flag) {
                    this.question = res.data;
                    this.questionList[this.currentIndex] = res.data;
                    const answer = this.answerList[this.currentIndex];
                    const beforeAnswer = this.idList[this.currentIndex].AnswerIds;
                    if (!answer && beforeAnswer) {
                        this.answerList[this.currentIndex] = res.data.QuestionType === 2 ? beforeAnswer.split(',') : beforeAnswer;
                    }
                    if (this.isAutoPlay) {
                        this.isAutoPlay = false;
                        this.$nextTick(() => {
                            this.autoPlay();
                        });
                    }
                }
            });
        },
        /**
         * 获取题目id列表
         */
        getQuestionIdList(notShowNotice = false) {
            if (this.$route.query.id > 0) {
                this.$api.sequenceQuestionList({
                    categoryId: this.$route.query.id,
                    carId: this.$store.state.car.selectId,
                    subjectId: this.$route.query.type
                }).then(res => {
                    if (res.flag) {
                        if (res.lastProblem > 0 && !notShowNotice) {
                            this.noticeVisible = true;
                        }
                        this.lastProblemId = res.lastProblem;
                        this.idList = res.data;
                        this.questionList = Array(res.data.length);
                        this.answerList = Array(res.data.length);
                        this.indexChange();
                    }
                });
            } else {
                this.$api.getErrorProblem({
                    carId: this.$store.state.car.selectId,
                    subjectType: this.$route.query.type
                }).then(res => {
                    this.idList = res.date;
                    this.questionList = Array(res.date.length);
                    this.answerList = Array(res.date.length);
                    this.indexChange();
                });
            }
        },
        // 移除答题记录
        removeRecord() {
            if (this.$route.query.id > 0) {
                this.$api.removeProblemRecord({
                    categoryId: this.$route.query.id,
                    carId: this.$store.state.car.selectId,
                    subjectType: this.$route.query.type
                }).then(res => {
                    if (res.flag) {
                        this.getQuestionIdList(true);
                    }
                });
            }
        },
        board() {
            this.isFlag = !this.isFlag;
        },
        // 多选题确认选择
        confirmSelect() {
            if (this.question.QuestionType !== 2) {
                this.$message({
                    message: '当前题目不是多选题',
                    type: 'error',
                    showClose: true
                });
                return;
            }
            const answer = this.answerList[this.currentIndex];
            const correctAnswerList = this.question.AnswerIds.split(',');
            if (answer) {
                // 正确就跳下一题
                if (answer.every(a => correctAnswerList.includes(a)) && answer.length === correctAnswerList.length) {
                    setTimeout(() => {
                        this.next();
                    }, 500);
                } else {
                    // 不正确就显示正确答案
                    this.$set(this.answerList, this.currentIndex, correctAnswerList);
                    this.playAudio();
                }
            } else {
                // 不正确就显示正确答案
                this.$set(this.answerList, this.currentIndex, correctAnswerList);
                this.playAudio();
            }
        },
        // 点击答案
        toggleClick(item) {
            const answer = this.answerList[this.currentIndex];
            if (this.question.QuestionType === 2) {
                if (answer) {
                    const index = answer.findIndex(a => a === item);
                    if (index >= 0) {
                        answer.splice(index, 1);
                    } else {
                        answer.push(item);
                    }
                    // this.answerList[this.currentIndex] = answer;
                    this.$set(this.answerList, this.currentIndex, answer);
                } else {
                    // this.answerList[this.currentIndex] = [item];
                    this.$set(this.answerList, this.currentIndex, [item]);
                }
            } else {
                this.currentAnswer = [];
                this.currentAnswer.push(item);
                this.answerList[this.currentIndex] = item;
                this.$set(this.answerList, this.currentIndex, item);
                if (item === this.question.AnswerIds) {
                    setTimeout(() => {
                        this.next();
                    }, 500);
                } else {
                    this.$set(this.answerList, this.currentIndex, this.question.AnswerIds);
                    this.playAudio();
                }
            }
        },
        // 显示答案前图标
        answerState(option) {
            const item = this.answerList[this.currentIndex];
            if (!item) {
                return require('../../images/radio.png');
            }
            if (this.question.QuestionType === 2) {
                if (item.some(a => a === option)) {
                    return this.question.AnswerIds.includes(option) ? require('../../images/radioa.png') : require('../../images/cuo.png');
                } else {
                    return require('../../images/radio.png');
                }
                // return item.some(a => a === option);
            } else {
                const currentAnswer = this.currentAnswer[0];
                if (currentAnswer && currentAnswer === option) {
                    return this.question.AnswerIds === option ? require('../../images/radioa.png') : require('../../images/cuo.png');
                }
                if (item === option) {
                    return this.question.AnswerIds === option ? require('../../images/radioa.png') : require('../../images/cuo.png');
                } else {
                    return require('../../images/radio.png');
                }
            }
            // return true;
        },
        clearAudio() {
            const player = this.$refs.audioPlayer;
            const titleplayer = this.$refs.titleAudioPlayer;
            player.pause();
            titleplayer.pause();
            player.currentTime = 0;
            titleplayer.currentTime = 0;
            this.clearAllAsyncFn();
        },
        // 题板点击
        handleIndexClick() {
            if (this.isAutoPlay) {
                this.clearAudio();
            }
        },
        // 上一题
        prev(needClear = false) {
            if (needClear) {
                this.clearAudio();
            }
            if (this.currentIndex <= 0) {
                this.$message({
                    type: 'info',
                    message: '已经是第一题了',
                    duration: 1500
                });
                return;
            }
            this.saveFinishedQuestion();
            this.currentIndex -= 1;
        },
        // 下一题
        next(needClear = false) {
            if (needClear) {
                this.clearAudio();
            }
            this.saveFinishedQuestion();
            this.currentIndex < this.idList.length - 1 && (this.currentIndex += 1);
        },
        // 保存题
        saveFinishedQuestion() {
            let isError = false;
            // const answer = this.answerList[this.currentIndex];
            const answer = this.question.QuestionType === 2 ? this.currentAnswer : this.currentAnswer[0];
            if (answer) {
                isError = this.question.QuestionType === 2 ? answer.some(a => this.question.AnswerIds.search(a) < 0) : (answer !== this.question.AnswerIds);
            }
            const item = this.idList[this.currentIndex];
            if (item) {
                item.IsError = isError;
                item.IsTest = !!answer;
                this.$set(this.idList, this.currentIndex, item);
            }
            if (item.IsTest) {
                const answerIds = this.question.QuestionType === 2 ? answer.join(',') : answer;
                this.$api.saveWrongQuestion({ problemId: this.question.Id, categoryId: Number(this.$route.query.id), isError, answerIds });
            }
        },
        // 展开解释
        explain() {
            this.isExplain = !this.isExplain;
        },
        // 显示
        showPopup() {
            this.isPopup = true;
        },
        hidePopup() {
            this.isPopup = false;
        },
        goBack() {
            this.$router.go(-1);
        }
    }
};
</script>
