<template>
    <!-- .03 题板-->
    <div
        class="board animated fadeIn"
        v-if="isShow"
    >
        <ul>
            <li
                v-for="(item, index) in list"
                :key="index"
                :class="{ red: item.IsError, green: item.IsTest && !item.IsError,gray:index === currentIndex }"
                @click="handleClick(index)"
            >{{ index + 1 }}</li>
        </ul>
    </div>
</template>
<script>
export default {
    name: '',
    props: {
        isShow: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: () => []
        },
        currentIndex:
        {
            type: Number,
            default: 0
        }
    },
    data() {
        return {};
    },
    methods: {
        handleClick(index) {
            if (index !== this.currentIndex) {
                this.$emit('update:currentIndex', index);
                this.$emit('indexClick');
            }
        }
    }
};
</script>
<style lang="scss" scoped>
@import '@/styles/public.scss';
@import '@/styles/exercises.scss';

.board {
    overflow: visible;
    width: fit-content;
    margin: 30px 0px 0px 80px;
    border: solid 1px #f0f0f0;
    border-right: none;

    ul {
        overflow: hidden;
        li {
            overflow: hidden;
            width: 38.28px;
            height: 38.28px;
            font-size: 16px;
            color: #333;
            box-sizing: border-box;
            float: left;
            text-align: center;
            line-height: 38.28px;
            border-right: solid 1px #f0f0f0;
            border-bottom: solid 1px #f0f0f0;
            cursor: pointer;
            box-sizing: content-box;
            &.gray {
                background-color: #eee;
            }
        }
        li.red {
            background-color: #ff470f;
            color: #fff;
        }
        li.green {
            background-color: #37c648;
            color: #fff;
        }
    }
}
</style>
